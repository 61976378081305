import { useEffect } from "react";

const AppQrCodePage = () => {
    useEffect(() => {
        const os = (() => {
            const { userAgent } = navigator;

            // Windows Phone must come first because its UA also contains "Android"
            if (/windows phone/i.test(userAgent)) {
                return "windows phone";
            }

            if (/android/i.test(userAgent)) {
                return "android";
            }

            // iOS detection from: http://stackoverflow.com/a/9039885/177710
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return "ios";
            }

            return undefined;
        })();

        const links = {
            android:
                "https://play.google.com/store/apps/details?id=de.hoyer.mobil.hoyer_energie",
            ios: "https://apps.apple.com/de/app/hoyer-energie-technik/id1478872277",
        };
        const target = links[os];

        if (target) {
            // eslint-disable-next-line no-restricted-globals
            location.href = target;
        } else {
            // eslint-disable-next-line no-restricted-globals
            location.href = links.android;
        }
    }, []);

    return null;
};
export default AppQrCodePage;
